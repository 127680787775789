<template>
	<div v-if="!loading" class="space-css content-padding">
        <BaseCard :padding="'25px'">
            <h3>
                {{ labels.sass_css_style_editor }}
            </h3>
            <div class="spacer"></div>
            <CodeEditor
                class="code-editor"
                :data-object="{
                    language: 'css',
                    code: localData.customStyle || '',
                    minHeight: '300px'
                }"
                :enabled="true"
                @codeEditor-input="codeUpdated"
            />
        </BaseCard>
	</div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { debounce } from "@/services/debounce";

    export default {
        name: 'SpaceCss',
        components: {
            BaseCard: () => import('@/components/Cards/BaseCard'),
            CodeEditor: () => import('@/components/Input/CodeEditor.vue')
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            localData: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            spaceFields: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters('helper', ['labels'])
        },
        methods: {
            codeUpdated(value) {
                debounce(() => {
                    this.$emit('data-updated', 'customStyle', value);
                }, 200, `space-input-customStyle`);
            },
        },
        mounted() {
            
        },
        beforeRouteLeave (to, from, next) {
            next();
        },
    }
</script>

<style lang='scss' scoped>
    .space-css {
        > h3 {
            margin-bottom: 0;
        }

        .spacer {
            margin-top: 1rem;
            margin-bottom: 1rem;
            height: 1px;
            width: 100%;
            background-color: rgba(0,0,0,0.3);
        }
    }
</style>